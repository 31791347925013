@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;800&display=swap");
@import url("https://use.typekit.net/jkv5qjh.css");

#root {
  margin-top: 0;
}

body {
  color: #23143f !important;
}

@import "./partials/abstracts/variables";
@import "./partials/abstracts/tailwind-extensions";
@import "./partials/components/inputs";

@import "./partials/components/animations";

@import "./partials/base/ant";
@import "./partials/layout/nav";
@import "./partials/layout/sidebar";
@import "./partials/components/buttons.scss";
@import "./partials/components/buttons.scss";
@import "./partials/components/create.scss";
@import "./partials/components/modals.scss";
@import "./partials/components/empty-data";
@import "./partials/components/media-thumbnail";
@import "./partials/pages/login";
@import "./partials/pages/admin";
@import "./partials/pages/verification-tasks";
