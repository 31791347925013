.ant-input-group-addon,
.ant-input-group-wrap {
  width: 8rem !important;
  white-space: nowrap;
  vertical-align: middle;
}

.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  height: 55px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  height: auto !important;
  display: flex;
  align-items: center;
}

.ant-input-affix-wrapper-lg {
  border-radius: 8px !important;
}

.ant-input-number-affix-wrapper-lg {
  border-radius: 8px !important;
}

.ant-input-number-prefix,
.ant-input-number-suffix {
  pointer-events: all !important;
}
.ant-input-prefix {
  margin-right: 0.95rem !important;
  margin-left: 0.35rem !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  display: flex;
  align-items: center;
}

.ant-table-thead > tr > th {
  background: #fff !important;
  color: #7c7984 !important;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  opacity: 0.5 !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none !important;
}

.ant-tag {
  border: none !important;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.ant-tag-orange {
  color: #f08922 !important;
  background: rgba(240, 137, 34, 0.1) !important;
}
.ant-tag-green {
  color: #1eb12d !important;
  background: rgba(30, 177, 45, 0.1) !important;
}
.ant-tag-red {
  color: #e94444 !important;
  background: rgba(233, 68, 68, 0.1) !important;
}

.custom-input input {
  width: 100% !important;
  //height: 5.6rem !important;
}
.ant-upload-list-item {
  display: none !important;
}
.ant-pagination-item,
.ant-pagination-item-link {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-input-lg,
.ant-input-number-lg input {
  //margin-top: 2rem !important;
}

input#bvn.ant-input {
  height: 5.6rem !important;
  margin-top: -0.7rem !important;
  background-color: transparent !important;
}

#dynamic__form .ant-form-item{
  margin-bottom: 6px !important;
}

@mixin hover-effect {
  &:hover{
    border: 1px solid #1890ff !important;
  }
}

.react-tel-input {
  .form-control {
    height: 3.5rem !important;
    width: 100% !important;

    @include hover-effect;
  }

  .selected-flag {
    width: 40px !important;

    @include hover-effect;
  }
}
