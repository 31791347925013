.ant-tag {
  width: 100%;
  text-align: center;
}
.ant-pagination {
  li {
    border-radius: 4px !important;
  }
  .ant-pagination-item-active {
    background-color: $primary !important;
    a {
      color: white !important;
    }
  }
}

.ant-pagination-item,
.ant-pagination-item-link {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  display: flex !important;
  align-items: center !important;
}

.ant-layout.ant-layout-has-sider {
  margin-left: 0 !important;
  margin-top: 75px;
  width: 100% !important;
}

.ant-layout-sider {
  margin-top: 0 !important;
}

.ant-btn-primary {
  background: $primary !important;
  border-color: $primary !important;
}

.ant-btn-dangerous.ant-btn-primary {
  background: $danger !important;
  border-color: $danger !important;
}

.ant-btn-primary[disabled] {
  background: #e8e5e5 !important;
  border-color: #e8e5e5 !important;
  color: $text-darken !important;
}

li.ant-menu-item.ant-menu-item-only-child {
  padding: 0 !important;
}

.ant-menu-item-selected {
  color: #fff !important;
  border-right: 3px solid rgba(255, 255, 255, 0.1) !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  height: 4rem !important;
  display: flex !important;
  align-items: center !important;
  //padding-left: 2.4rem !important;
}

.menu-item {
  color: #fff !important;
}

.ant-card-body {
  height: 100% !important;
  width: 100% !important;
}

.ant-tabs-tab {
  height: 58px !important;
  border: none !important;
  border-radius: 16px 16px 0px 0px !important;
  box-shadow: 5px -2px 10px rgba(109, 165, 68, 0.05) !important;
  background: rgba(3, 87, 238, 0.05) !important;
  color: $primary-dark-70 !important;
  margin-right: 13px !important;
}

.ant-tabs-tab .tab-count-tag {
  background-color: $primary-dark-70;
}

.ant-tabs-tab.ant-tabs-tab-active .tab-count-tag {
  background-color: $primary;
}

.ant-tabs-nav {
  margin-bottom: 0px !important;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.sidebar-menu-list {
  background: #1e333e !important;
}

.ant-tabs-tab-active {
  background-color: #fff !important;
  color: $primary !important;
  border: none !important;
}
.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}
.ant-pagination-item-active {
  margin-top: 0.1rem !important;
}

.ant-btn {
  border-radius: 4px !important;
  height: 48px !important;
}

.cameraModal {
  background-color: #fff !important;
}

.ant-layout.ant-layout-has-sider {
  margin-top: 0 !important;
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-body .pb-5:first-of-type,
.ant-modal-body .py-5:first-of-type {
  padding: 2.4rem;
  position: relative;
}

// TABLE
.ant-table-wrapper {
  min-width: 1032px !important;
}

.table-sm .ant-table-thead > tr > th {
  padding-bottom: 8px !important;
  padding-left: 0px !important;
}
.table-sm .ant-table-tbody > tr > td {
  padding: 20px 0px !important;
}

.ant-table-thead .ant-table-cell {
  font-size: 12px !important;
  color: $primary-dark-70 !important;
  font-weight: 500;
}

.ant-table-row .ant-table-cell {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  cursor: pointer;
}

.ant-table-thead > tr > th {
  border-bottom: none !important;
}

.ant-modal-content {
  background-color: transparent !important;
}

.ant-modal-footer {
  background: #fff !important;
}

.right__radio span.ant-radio {
  right: -31rem !important;
  top: -12px !important;
}

@media only screen and (max-width: 320px) {
  .right__radio span.ant-radio {
    right: -10rem !important;
    top: 10px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 429px) {
  .right__radio span.ant-radio {
    right: -14rem !important;
    top: 10px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 957px) {
  .right__radio span.ant-radio {
    right: -15rem !important;
    top: 10px !important;
  }
}

@media only screen and (min-width: 1300px) {
  .right__radio span.ant-radio {
    right: -31rem !important;
    top: 0 !important;
  }
}

.ant-collapse-content > .ant-collapse-content-box {
  background: rgba(3, 87, 238, 0.05) !important;
}

.ant-collapse {
  border-radius: 8px !important;
}

.ant-collapse-expand-icon {
  position: absolute !important;
  right: 0 !important;
}

.ant-statistic-content-value {
  color: #fff !important;
}

.ant-image-preview-body {
  top: -85% !important;
  left: 26% !important;
}

.ant-popover-buttons .ant-btn.ant-btn-primary.ant-btn-sm {
  height: 32px !important;
}
.ant-popover-buttons .ant-btn.ant-btn-default.ant-btn-sm {
  height: 32px !important;
}

.ant-progress-circle .ant-progress-text {
  font-weight: 700 !important;
}
.ant-image{
  width: 60px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 56px !important;
}
