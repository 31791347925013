$white: #ffffff;

// Colors
$cultured-white: #F7F8F9;
$gray: #fafbfc;


$text: #74708d;
$text-lighten: #c0bdd0;
$text-darken: #615d7c;
$text-darken-more: #514d6a;
$text-body: #23143f;

$gray: #9ba6b9;
$gray-lighten: #eef0f4;
$gray-lighten-more: #f2f4f8;
$gray-lighten-more-more: #f9fafc;
$gray-darken: #132128;
$gray-border: #e4e9f0;
$gray-100: #fafbfc;

$black: #0e0b20;
$black-lighten: #222034;
$black-lighten-more: #393749;
$black-100: #4e5d78;

$red-700: #00000033;
$red-800: #ffffff1a;
$red-900: #e944441a;


$blue: #0088ff;
$blue-darken: #0072d6;
$blue-lighten: #f2f6ff;
$blue-100: #f1f7fe;
$blue-200: #EAEEF959;
$blue-300: #d4ebf517;
$blue-400: #e2ecfc;
$blue-500: #0361F01A;
$blue-600: #0b58cd1f;
$blue-700: #b4cbd50d;
$blue-800: #0357ee33;
$blue-900: #0357ee1a;
$orange: #f2a654;
$yellow: yellow;

$menuBg: #001529;
$menuBg-darken: #000c17;

// Accent colors
$default: #acb7bf;
$primary: #0361f0;
$primary-light: #35d015;
$primary-dark: #0d470db2;
$primary-dark-70: rgba(11, 50, 117, 0.7);
$primary-dark-90: rgba(11, 50, 117, 1);
$secondary: #1a911d0d;
$success: #46be8a;
$info: #0887c9;
$warning: #f39834;
$danger: #fb434a;

// Font Family
$base__font-family: 'Inter', sans-serif !important;

// Font Size
$base__font-size: 13 !default;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;
