.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0px 15px;
  width: 100%;
  height: 84px;
  border: 1px solid rgba(3, 87, 238, 0.2);
}

.ant-table-thead .ant-table-cell {
  font-size: 14px;
  color: rgb(124, 121, 132);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  cursor: pointer;
}

.admin-profile {
  display: flex;
}
.admin_profile {
  display: flex;
  margin-right: 20px;

  img {
    padding-right: 10px;
  }
}
.admin_home {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-top: 4rem;
  background-color: $blue-100 !important;
}

.admin_content {
  display: flex;
  flex-direction: column;
  padding: 25px 51px 51px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
  }
}
@media only screen and (max-width: 600px) {
  .admin_content {
    display: flex;
    flex-direction: column;
    padding: 13px 24px 24px;
  }
  .admin_content.mobile_no_padding {
    padding: 0px !important;
  }
}
.admin_scroll {
  width: 1050px;
  margin-left: 40px;
}
.admin_card {
  display: flex;
  justify-content: center;
  background: #ffffff;
  width: 280px;
  height: 90px;
  box-shadow: 0px 1px 2px rgba(3, 97, 240, 0.1);
  border-radius: 8px;
  justify-content: space-around;
  align-items: center;
}

.rate_button {
  font-size: 14px;
  width: 95px;
  height: 45px;
  background: $primary;
  color: white;
}
.rate_button_modal {
  font-size: 14px;
  width: 110px;
  height: 45px;
  background: $primary;
  color: white;
}
.admin_button {
  display: flex;
  width: 202px;
  height: 56px;
  justify-content: center;
  align-items: center;
  background: $primary;
  border-radius: 4px;
  color: white;
}

.admin_butt {
  display: flex;
  width: 150px;
  height: 46px;
  justify-content: center;
  align-items: center;
  background: $primary;
  border-radius: 4px;
  color: white;
  margin-top: 20px;
}

.admin_flag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  img {
    margin-right: 7px;
  }
}

.admin_div {
  p {
    font-size: 20px;
    font-weight: 600;
  }
}
.monie-logo {
  width: 100px;
}
.arrow {
  width: 12.62px;
}
.admin_trade {
  width: 100%;
  padding: 24px 32px;
  background-color: $white;
  border-radius: 16px;
}

.admin_world {
  width: 100%;
  height: 100%;
  background-color: $primary-light;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem 1rem;
}
.home__new-trade .inner__container img {
  width: 9.27rem;
  height: 11.2rem;
}
.admin_card_body {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 32px;
  background: #ffffff;
  border-radius: 16px;
  width: 970px;
  padding: 24px;
  margin-left: 85px;
  margin-top: 50px;
}

.admin_sideNav button {
  font-size: 24px;
}

.rates_input {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 9px;
}

.pending-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.currency {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  object-fit: cover;
}
