.navigation {
  background-color: $blue-lighten;
}
.nav {
  position: fixed;
  background: $white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  border-top: 4px solid $primary;
  border-bottom: 1px solid $blue-800;
  padding: 16px;
  padding-bottom: 23px;
}

.nav__list {
  width: 100%;
  margin: 0;
}

.nav__item {
  margin: 5px 20px;
  border-bottom: 1px dashed lightgrey;
  list-style: none !important;
  cursor: pointer;
  transition: all 0.15s;
  padding-bottom: 10px;
  padding-left: 14px;
}

.nav__item:hover,
.nav__item.active {
  border-bottom: 3px solid $primary;
  color: $primary;
}

.nav__brand {
  width: auto;
  height: 53px;
  object-fit: contain;
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.15s;
  padding-left: 0 !important;
}

.collapsible--expanded {
  width: 100%;
}

.collapsible--expanded .collapsible__content {
  max-height: 100%;
  opacity: 1;
}

.nav__toggler {
  margin-right: 20px;
  /*opacity: 0.5;*/
  transition: all 0.15s;
  cursor: pointer;
}

.nav.collapsible--expanded .nav__toggler {
  /*opacity: 1;*/
  border-radius: 5px;
}

.nav__account {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav__account .user-container {
  width: 49px;
  height: 50px;
  border-radius: 15px;
  //background-color: $primary-light;
  background-color: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-container {
  width: 4.9rem;
  height: 5rem;
  border-radius: 15px;
  //background-color: $primary-light;
  background-color: $secondary;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .nav__list {
    width: auto;
    display: flex;
    font-size: 14px;
    max-height: 100%;
    opacity: 1;
    padding-bottom: 0.3rem;
  }
}
@media (max-width: 600px){
  .navigation, .nav {
    background-color: $blue-100;
    border-bottom: none;
  }
} 