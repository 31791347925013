@tailwind base;
@tailwind components;
@tailwind utilities;

h3 {
  @apply text-2xl font-bold;
}

.center-container {
  @apply flex items-center justify-center;
}

.flex-center {
  @apply flex items-center;
}

.task-stepper .task-stepper-step-container {
  min-height: 85px;
  position: relative;
}

.task-stepper-step:not(:last-child)::after {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #e2ecfc;
  position: absolute;
  bottom: 0;
}
.task-stepper .task-stepper-step-container:last-child {
  min-height: 55px;
}
.task-stepper .task-stepper-step-container:last-child::after {
  background-color: transparent;
}

.backdrop-blur {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

.step-item-last-container {
  margin-bottom: 50px;
}

.step-item-active::before {
  background-color: #0361f0;
  content: "";
  height: calc(100% - 26px);
  width: 2px;
  position: absolute;
  bottom: 26px;
  left: 13px;
}
.step-item-completed::before {
  background-color: #0361f0;
  content: "";
  height: calc(100% - 26px);
  width: 2px;
  position: absolute;
  bottom: 26px;
  left: 13px;
}
.step-item-default::before {
  background-color: #d0d5d7;
  content: "";
  height: calc(100% - 26px);
  width: 2px;
  position: absolute;
  bottom: 26px;
  left: 13px;
}

.step-item-last::after {
  content: "";
  min-height: 100vh;
  height: 450vh;
  width: 2px;
  background-color: #d0d5d7;
  position: absolute;
  top: 85px;
  left: 13px;
}

.nav-item_active span {
  color: #0361f0 !important;
}
.top-nav {
  border-bottom: 1px solid #0357ee33;
}

/* DASHBOARD */
.banner-card {
  box-shadow: 0px 3px 3px 0px #0b58cd1f;
}

.cashout-pin .ant-input-affix-wrapper .ant-input-password {
  padding: 18px 16px;
}

/* WALLET FILTER */
.select-modal-option {
  @apply font-medium text-sm p-2 block w-full text-left hover:bg-primary-blue-off-blue rounded-md;
}

.select-modal-wrapper {
  @apply absolute bg-white rounded-xl w-full p-2 z-40 mt-1;
}
.select-modal-shadow {
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.ant-picker.ant-picker-range {
  border-radius: 4px !important;
  outline: none !important;
}
.ant-picker.ant-picker-range:hover {
  outline: none !important;
}
@media (min-width: 600px){
  .dashboard-summary {
    min-width: 1098px;
  }
  .dashboard-summary .banner-card {
    height: 135px;
  }
  .banner-card.wallet-card {
    width: 354px;
  }
}
@media (max-width: 600px){
  .task-stepper .task-stepper-step-container {
    min-height: 50px;
    height: fit-content;
  }
}
