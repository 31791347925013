.login {
  display: flex;
  background-size: cover;
  min-height: 100vh;
  padding: 20px 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: 496px;
  height: auto;
  border-radius: 8px;
  background: white;
  padding: 40px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
  border-radius: 8px;
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0px 8px;
    margin-bottom: 35px;
  }
}

.highlight {
  display: none;
  width: 415px;
  height: 48px;
  display: flex;
  align-items: center;
  background: $secondary;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 15px;

  p {
    font-size: 13px;
  }
  img {
    width: 55px;
    height: 55%;
    margin-right: 3px;
  }
}

.icon {
  width: 16px;
  height: 20px;
  margin: 0px 8px;
  color: #9ba6b9;
}

.wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url("../../../images/loginBackground.svg.svg") no-repeat 50%;
}

.inner-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 361px;
  width: 496px;
  left: 472px;
  top: 259px;
  border-radius: 8px;
  padding: 40px;
}

.inner-content {
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0px;
  }
  a {
    color: $primary;
    margin: 30px 8px;
    text-align: center;
    font-weight: 500;
  }
}

.login__new {
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  width: 22.9rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop-blur {
  background-color: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(5px);
}

@media (max-width:640px) {
  // Override card body padding for mobile
  .login-card .ant-card-body {
    padding: 20px !important;
  }
  .login-mobile {
    width: calc(100% - 40px);
    //40px is the margin around the container
  }
}