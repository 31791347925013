.create__right-col {
  background-color: #FAFBFC;
  padding: 52px 84px 27px;
  min-height: 40rem;
  max-height: 71rem;
  overflow: auto;
  width: 61%;
}

.create__cols-container {
  background: #ffffff;
  //margin: -2rem !important;
  //max-height: 75.1rem;
  min-width: 100%;
}

.create__left-col {
  display: none;
}

.create__right-col__divider {
  border: 1px dashed rgba(11, 50, 117, 0.2);
  transform: rotate(90deg);
  height: 0;
  position: absolute;
}

.create__right-col__divider-one {
  width: 3rem;
  left: 2rem;
  top: 6rem;
}
.create__right-col__divider-two {
  width: 4rem;
  left: 1.5rem;
  top: 10.8rem;
}
.create__right-col__divider-three {
  width: 4rem;
  left: 1.5rem;
  top: 15rem;
}

.create__right-col__dropdown {
  border-radius: 0 0 16px 16px;
  padding: 1rem 0;
}



@media (min-width: 350px) {
  .create__right-col__divider-two {
	top: 10rem;
  }
  .create__right-col__divider-three {
	top: 15rem;
	left: 2.2rem;
	width: 2.7rem;
  }
}

@media (min-width: 768px) {
  .create__left-col {
	display: block;
  }
  .create__left-col .step {
	position: absolute;
  }
  .create__right-col__divider-three {
	top: 15.5rem;
  }
  .create__right-col {
	padding-bottom: 0;
  }
  .create__cols-container {
	//max-height: 95rem;
  }
}

@media (min-width: 1024px) {
  .create__right-col__divider-one {
	width: 1.4rem;
	left: 3%;
	top: 28%;
  }
  .create__right-col__divider-two {
	width: 1.4rem;
	left: 3%;
	top: 69%;
  }
  .create__right-col__divider-three {
	top: 15rem;
	left: 2.2rem;
  }
}

.review .review__image-holder, filter{
  border: 4px solid #fff;
  border-radius: 9px;
  width: 60px;
  height: 60px;
}
