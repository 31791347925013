.right-col-max-width {
  max-width: 503px;
}

.review__card {
  background: $blue-100;
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
}

.review__tags {
  width: auto !important;
}

.camera__bg {
  height: 416px;
}

.video__bg {
  min-height: 458px;
  position: relative;
}
.status-pill {
  color: #f08922;
  background: rgba(240, 137, 34, 0.1);
  border-radius: 4px;
  text-align: center;
  min-width: 65px;
  width: fit-content;
  padding: 4px 8px;
}

@media only screen and (min-width: 768px) {
  .review__card {
    background: $blue-100;
    border-radius: 10px;
    width: 480px;
  }
}

.tasks__left {
  width: 39%;
}

.tasks__right {
  padding-left: 5rem;
  padding-right: 5rem;
  width: 61%;
}
.mobile_task_container {
  background: $blue-100 !important;
  padding: 13px 24px 24px;
}
.mobile_task_button {
  background: rgba(3, 87, 238, 0.05);
  color: $primary-dark-70 !important;
  .tab-count-tag {
    background-color: $primary-dark-70;
  }
}
.mobile_task_button.button_active {
  background-color: $primary !important;
  color: #fff !important;
  .tab-count-tag {
    background-color: $primary-dark-90;
  }
}
@media only screen and (min-width: 768px) {
  .tasks__right {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .tasks__right {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media (max-width: 600px) {
  .verification-task-list .ant-card-body {
    padding: 15px;
  }
  .tasks__right {
    padding: 20px;
    width: 100%;
  }
  .tasks__left {
    width: 100%;
  }
  .status-pill {
    width: 65px;
    padding: 2px 4px;
  }
}
.dotted-box {
  border: 1px dashed $red-700;
  border-radius: 16px;
  padding: 32px 0;
}
.liveness-check-btn {
  min-width: 222px;
}
.verification-task-details-container {
  width: 47%;
  padding: 32px 32px 27px;
}
.verification-task-stepper-container {
  padding: 32px 56px;
}
.verification-details-container {
  width: 53%;
  min-height: 599px;
  background-color: $blue-700;
}
.verification-results-details-container {
  width: 53%;
  min-height: 599px;
}
.verification-warning-container {
  background: $red-900;
}
.verification-task-details-icon-container {
  width: 151px;
  height: 151px;
  background-color: $blue-lighten;
}
.verification-progress svg.ant-progress-circle {
  background: $white;
}
.verification-layout-child-container {
  box-shadow: 0px 3px 3px 0px $blue-600;
}
.custom-task-pill-border-radius {
  border-radius: 0px 16px 16px 16px;
}
.pending-task-container {
  box-shadow: 0px 3px 3px 0px $blue-600;
}
@media (max-width: 600px) {
  .verification-task-stepper-container {
    padding: 20px;
  }
}

.verification-task-filter-pills {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  height: 40px;
  padding: 7px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  width: 100%;
}

@media (min-width: 768px) {
  .verification-task-filter-pills {
    width: auto;
    margin-bottom: 0;
  }
}

.filter-bar .ant-tag {
  width: auto !important;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  border-radius: 10px !important;
}

@media (max-width: 768px) {
  .tasks__right {
    padding: 20px;
    width: 100%;
  }
  .verification-task-details-container,
  .verification-results-details-container {
    width: 100%;
    padding: 20px;
  }
  .verification-details-container {
    width: 100%;
    min-height: auto;
  }
}
