.filter_btn {
  align-items: center;
  border-radius: 4px;
  color: $primary;
  height: 57px !important;
  justify-content: center;
  padding: 7px 24px 7px 16px;
  width: 103px;
  border: none;
}

.primary_icon {
  color: $primary;
}

.ant-btn-ghost {
  background: rgba(255, 255, 255, 0.1) !important;
  border: none;
  border-radius: 4px !important;
  color: white !important;
}

.btn_large, .btn_medium {
  height: 56px;
  width: 100%;
}

@media (min-width: 501px) {
  .btn_large {
	height: 56px;
	width: 214px;
  }
  .btn_medium {
	height: 56px;
	width: 120px;
  }
}
