.text-coral-black {
  color: $black-100;
}
.bg-cultured-white {
  background: $cultured-white;
}
.action-failed-icon, .modal-icon {
  width: 5.3rem;
  height: 5.3rem;
}
.modal-retry-button {
  width: 100%;
  height: 5.6rem;
}
.modal-successful-withdrawal-icon {
  width: 73px;
  height: 73px;
}
.modal-liveness-check-camera-icon {
  width: 45px;
  height: 45px;
}
