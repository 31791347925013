.width-26 {
  width: 1.625rem; //26px
}
.width-45 {
  width: 2.813rem; //45px
}
.height-45 {
  height: 2.813rem; //45px
}
.height-56 {
  height: 3.5rem; //56px
}
.height-72 {
  height: 4.5rem;
}
.height-120 {
  height: 7.5rem; //120px
}
.border-radius-5 {
  border-radius: 0.313rem; //5 rem
}
.border-radius-10 {
  border-radius: 0.625rem;
}
.text-size-28 {
  font-size: 1.75rem; // 28px
}
.minimum-height-70 {
  min-height: 4.375rem; //70px
}
.minimum-width-80 {
  min-width: 5rem; //80px
}
.maximum-width-490 {
  max-width: 490px;
}
.custom-blue-background {
  background: $blue-900;
}
.custom-indigo-lavendar-background {
  background: $blue-400;
}
.custom-box-shadow {
  box-shadow: 1px 2px 0px $blue-900;
}
.text-color-inherit {
  color: inherit;
}
.custom-tag-padding {
  padding: 3px 8px;
}
.custom-modal-button {
  width: 18.2rem;
  height: 5.6rem;
}
.custom-modal-button-height {
  height: 5.6rem;
}
.custom-grey-background {
  background-color: $gray-100;
}
.custom-light-blue-background {
  background: $blue-100;
}
