.empty-data-container {
  height: "55.9rem";
}
.empty-data-quoted {
  border-radius: 0px 16px 16px 16px;
  border: none;
  margin-top: -2.2rem;
}
.empty-data-unquoted {
  border-radius: 16px;
  margin-top: 0px;
}
.empty-data-icon {
  width: 14.56rem;
  height: 14.44rem;
}
.empty-data-button {
    height: 4.8rem;
}
